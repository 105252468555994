export const mpo: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Marshall Toews",  top_3_points: 564,  total_points: 859,  events: [{ name: "spring_fling_2024", points: 121 }, { name: "battle_for_baskets_2024", points: 121 }, { name: "man_i_love_frisbee_open_2024", points: 92 }, { name: "provincials_2024", points: 241 }, { name: "portage_open_2024", points: 112 }, { name: "fall_classic_2024", points: 172 }] },
    { id: 1,  place: "2",  name: "Bryce Kraushar",  top_3_points: 562,  total_points: 868,  events: [{ name: "spring_fling_2024", points: 150 }, { name: "battle_for_baskets_2024", points: 103 }, { name: "pvo_2024", points: 102 }, { name: "man_i_love_frisbee_open_2024", points: 70 }, { name: "provincials_2024", points: 211 }, { name: "portage_open_2024", points: 71 }, { name: "fall_classic_2024", points: 161 }] },
    { id: 2,  place: "3",  name: "Eric Giesbrecht",  top_3_points: 497,  total_points: 786,  events: [{ name: "spring_fling_2024", points: 101 }, { name: "battle_for_baskets_2024", points: 91 }, { name: "pvo_2024", points: 111 }, { name: "man_i_love_frisbee_open_2024", points: 81 }, { name: "provincials_2024", points: 191 }, { name: "portage_open_2024", points: 56 }, { name: "fall_classic_2024", points: 155 }] },
    { id: 3,  place: "4",  name: "Bryan Freese",  top_3_points: 450,  total_points: 527,  events: [{ name: "battle_for_baskets_2024", points: 113 }, { name: "pvo_2024", points: 87 }, { name: "provincials_2024", points: 221 }, { name: "portage_open_2024", points: 106 }] }, 
    { id: 4,  place: "5",  name: "Brad Gerbrandt",  top_3_points: 409,  total_points: 511,  events: [{ name: "battle_for_baskets_2024", points: 83 }, { name: "pvo_2024", points: 87 }, { name: "man_i_love_frisbee_open_2024", points: 39 }, { name: "provincials_2024", points: 171 }, { name: "fall_classic_2024", points: 131 }] },
    { id: 5,  place: "6",  name: "Devin Koop",  top_3_points: 393,  total_points: 466,  events: [{ name: "battle_for_baskets_2024", points: 83 }, { name: "pvo_2024", points: 91 }, { name: "provincials_2024", points: 191 }, { name: "fall_classic_2024", points: 101 }] },    
    { id: 6,  place: "7",  name: "Josh Logozar",  top_3_points: 347,  total_points: 411,  events: [{ name: "spring_fling_2024", points: 36 }, { name: "battle_for_baskets_2024", points: 10 }, { name: "pvo_2024", points: 32 }, { name: "man_i_love_frisbee_open_2024", points: 26 }, { name: "provincials_2024", points: 141 }, { name: "portage_open_2024", points: 80 }, { name: "fall_classic_2024", points: 86 }] },
    { id: 7,  place: "8",  name: "Nick Briere",   top_3_points: 343,  total_points: 415,  events: [{ name: "spring_fling_2024", points: 81 }, { name: "pvo_2024", points: 42 }, { name: "provincials_2024", points: 151 }, { name: "portage_open_2024", points: 91 }, { name: "fall_classic_2024", points: 50 }] },
    { id: 8,  place: "9",  name: "Colin McDonell",  top_3_points: 234,  total_points: 234,  events: [{ name: "pvo_2024", points: 10 }, { name: "provincials_2024", points: 123 }, { name: "fall_classic_2024", points: 101 }] },
    { id: 9,  place: "10",  name: "Jean-Paul Match",  top_3_points: 232,  total_points: 232,  events: [{ name: "spring_fling_2024", points: 91 }, { name: "fall_classic_2024", points: 141 }] },
    { id: 10,  place: "11",  name: "Kevin Desrosiers", top_3_points: 151,  total_points: 151,  events: [{ name: "spring_fling_2024", points: 28 }, { name: "battle_for_baskets_2024", points: 36 }, { name: "pvo_2024", points: 10 }, { name: "man_i_love_frisbee_open_2024", points: 10 }, { name: "provincials_2024", points: 10 }, { name: "portage_open_2024", points: 47 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 11,  place: "12",  name: "Terrell Feakes",  top_3_points: 131,  total_points: 131,  events: [{ name: "spring_fling_2024", points: 131 }] },
    ]
    
    export const fpo: PlayerWithEvents [] = [
    ]
    
    export const ma1: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Timothy Farrell",  top_3_points: 468,  total_points: 636,  events: [{ name: "spring_fling_2024", points: 92 }, { name: "battle_for_baskets_2024", points: 112 }, { name: "pvo_2024", points: 171 }, { name: "man_i_love_frisbee_open_2024", points: 52 }, { name: "provincials_2024", points: 145 }, { name: "portage_open_2024", points: 54 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 1,  place: "2",  name: "Sunny Dhillon",  top_3_points: 423,  total_points: 607,  events: [{ name: "spring_fling_2024", points: 102 }, { name: "battle_for_baskets_2024", points: 131 }, { name: "pvo_2024", points: 151 }, { name: "provincials_2024", points: 102 }, { name: "portage_open_2024", points: 111 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 2,  place: "3",  name: "Glenn Larmour",  top_3_points: 418,  total_points: 494,  events: [{ name: "spring_fling_2024", points: 74 }, { name: "battle_for_baskets_2024", points: 62 }, { name: "pvo_2024", points: 202 }, { name: "man_i_love_frisbee_open_2024", points: 10 }, { name: "provincials_2024", points: 102 }, { name: "portage_open_2024", points: 10 }, { name: "fall_classic_2024", points: 34 }] },
    { id: 3,  place: "4",  name: "David Harrison",  top_3_points: 374,  total_points: 588,  events: [{ name: "spring_fling_2024", points: 81 }, { name: "battle_for_baskets_2024", points: 84 }, { name: "pvo_2024", points: 101 }, { name: "man_i_love_frisbee_open_2024", points: 43 }, { name: "provincials_2024", points: 145 }, { name: "portage_open_2024", points: 88 }, { name: "fall_classic_2024", points: 46 }] },
    { id: 4,  place: "5",  name: "Kurtis Tallaire",  top_3_points: 337,  total_points: 446,  events: [{ name: "battle_for_baskets_2024", points: 122 }, { name: "pvo_2024", points: 58 }, { name: "provincials_2024", points: 71 }, { name: "portage_open_2024", points: 101 }, { name: "fall_classic_2024", points: 94 }] },
    { id: 5,  place: "6",  name: "Brandon Drozd",  top_3_points: 285,  total_points: 285,  events: [{ name: "man_i_love_frisbee_open_2024", points: 61 }, { name: "provincials_2024", points: 112 }, { name: "fall_classic_2024", points: 102 }, { name: "portage_open_2024", points: 10 }] },
    { id: 6,  place: "7",  name: "Jonathan Villaverde",  top_3_points: 270,  total_points: 362,  events: [{ name: "spring_fling_2024", points: 59 }, { name: "battle_for_baskets_2024", points: 91 }, { name: "pvo_2024", points: 78 }, { name: "provincials_2024", points: 71 }, { name: "portage_open_2024", points: 53 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 7,  place: "8",  name: "Aaron Magian",  top_3_points: 266,  total_points: 266,  events: [{ name: "spring_fling_2024", points: 125 }, { name: "battle_for_baskets_2024", points: 141 }] },
    { id: 8,  place: "9",  name: "DeShawn Klassen",  top_3_points: 243,  total_points: 243,  events: [{ name: "spring_fling_2024", points: 112 }, { name: "pvo_2024", points: 131 }] },
    { id: 9,  place: "10",  name: "David Sims",  top_3_points: 240,  total_points: 278,  events: [{ name: "battle_for_baskets_2024", points: 28 }, { name: "pvo_2024", points: 57 }, { name: "man_i_love_frisbee_open_2024", points: 30 }, { name: "provincials_2024", points: 102 }, { name: "fall_classic_2024", points: 61 }] },
    { id: 10,  place: "11",  name: "Thomas Fehr",  top_3_points: 171,  total_points: 171,  events: [{ name: "spring_fling_2024", points: 49 }, { name: "pvo_2024", points: 112 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 11,  place: "12",  name: "Graham Sereda",  top_3_points: 113,  total_points: 113,  events: [{ name: "spring_fling_2024", points: 24 }, { name: "battle_for_baskets_2024", points: 10 }, { name: "pvo_2024", points: 10 }, { name: "portage_open_2024", points: 69 }] },
    { id: 12,  place: "13",  name: "Xavier Tomanek",  top_3_points: 50,  total_points: 50,  events: [{ name: "battle_for_baskets_2024", points: 50 }] },
    { id: 13,  place: "14",  name: "Carlos Barkman",  top_3_points: 20,  total_points: 20,  events: [{ name: "spring_fling_2024", points: 10 }, { name: "fall_classic_2024", points: 10 }] },
    ]
    
    export const fa1: PlayerWithEvents [] = [
    ]
    
    export const ma2: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Airyn Clark",  top_3_points: 940,  total_points: 1385,  events: [{ name: "spring_fling_2024", points: 171 }, { name: "battle_for_baskets_2024", points: 10 }, { name: "battle_for_baskets_2024", points: 171 }, { name: "pvo_2024", points: 426 }, { name: "man_i_love_frisbee_open_2024", points: 162 }, { name: "provincials_2024", points: 142 }, { name: "fall_classic_2024", points: 303 }] },
    { id: 1,  place: "2",  name: "Anton Sawka",  top_3_points: 927,  total_points: 1286,  events: [{ name: "spring_fling_2024", points: 105 }, { name: "battle_for_baskets_2024", points: 184 }, { name: "pvo_2024", points: 391 }, { name: "man_i_love_frisbee_open_2024", points: 111 }, { name: "provincials_2024", points: 112 }, { name: "portage_open_2024", points: 71 }, { name: "fall_classic_2024", points: 312 }] },
    { id: 2,  place: "3",  name: "Joel Vankoughnet",  top_3_points: 783,  total_points: 866,  events: [{ name: "spring_fling_2024", points: 93 }, { name: "pvo_2024", points: 361 }, { name: "man_i_love_frisbee_open_2024", points: 151 }, { name: "fall_classic_2024", points: 261 }] },
    { id: 3,  place: "4",  name: "Everett Guenther",  top_3_points: 774,  total_points: 927,  events: [{ name: "spring_fling_2024", points: 152 }, { name: "battle_for_baskets_2024", points: 22 }, { name: "pvo_2024", points: 361 }, { name: "provincials_2024", points: 151 }, { name: "fall_classic_2024", points: 241 }] },
    { id: 4,  place: "5",  name: "Cameron Ward",  top_3_points: 763,  total_points: 1013,  events: [{ name: "spring_fling_2024", points: 181 }, { name: "battle_for_baskets_2024", points: 76 }, { name: "pvo_2024", points: 261 }, { name: "man_i_love_frisbee_open_2024", points: 62 }, { name: "provincials_2024", points: 142 }, { name: "fall_classic_2024", points: 291 }] },
    { id: 5,  place: "6",  name: "Trevor Menard",  top_3_points: 721,  total_points: 1212,  events: [{ name: "spring_fling_2024", points: 131 }, { name: "battle_for_baskets_2024", points: 193 }, { name: "pvo_2024", points: 201 }, { name: "man_i_love_frisbee_open_2024", points: 171 }, { name: "provincials_2024", points: 123 }, { name: "portage_open_2024", points: 106 }, { name: "fall_classic_2024", points: 287 }] },
    { id: 6,  place: "7",  name: "Chris Earnshaw",  top_3_points: 653,  total_points: 816,  events: [{ name: "spring_fling_2024", points: 171 }, { name: "battle_for_baskets_2024", points: 141 }, { name: "pvo_2024", points: 231 }, { name: "provincials_2024", points: 42 }, { name: "fall_classic_2024", points: 231 }] },
    { id: 7,  place: "8",  name: "Zachary Desrochers",  top_3_points: 618,  total_points: 834,  events: [{ name: "spring_fling_2024", points: 93 }, { name: "battle_for_baskets_2024", points: 87 }, { name: "pvo_2024", points: 391 }, { name: "man_i_love_frisbee_open_2024", points: 10 }, { name: "provincials_2024", points: 81 }, { name: "portage_open_2024", points: 94 }, { name: "fall_classic_2024", points: 78 }] },
    { id: 8,  place: "9",  name: "Riley Gauthier",  top_3_points: 600,  total_points: 673,  events: [{ name: "battle_for_baskets_2024", points: 98 }, { name: "pvo_2024", points: 361 }, { name: "man_i_love_frisbee_open_2024", points: 41 }, { name: "provincials_2024", points: 52 }, { name: "fall_classic_2024", points: 121 }] },
    { id: 9,  place: "10",  name: "Alex MacNeil",  top_3_points: 593,  total_points: 654,  events: [{ name: "spring_fling_2024", points: 71 }, { name: "battle_for_baskets_2024", points: 131 }, { name: "pvo_2024", points: 361 }, { name: "man_i_love_frisbee_open_2024", points: 91 }] },
    { id: 10,  place: "10",  name: "Tyler Halmrast",  top_3_points: 593,  total_points: 647,  events: [{ name: "spring_fling_2024", points: 64 }, { name: "battle_for_baskets_2024", points: 161 }, { name: "pvo_2024", points: 231 }, { name: "fall_classic_2024", points: 191 }] },
    { id: 11,  place: "12",  name: "Alex Loewen",  top_3_points: 499,  total_points: 543,  events: [{ name: "spring_fling_2024", points: 10 }, { name: "battle_for_baskets_2024", points: 54 }, { name: "pvo_2024", points: 261 }, { name: "man_i_love_frisbee_open_2024", points: 151 }, { name: "fall_classic_2024", points: 67 }] },
    { id: 12,  place: "13",  name: "Greg Lane",  top_3_points: 445,  total_points: 445,  events: [{ name: "spring_fling_2024", points: 152 }, { name: "pvo_2024", points: 102 }, { name: "fall_classic_2024", points: 191 }] },
    { id: 13,  place: "14",  name: "Eric Kulchycki",  top_3_points: 412,  total_points: 435,  events: [{ name: "battle_for_baskets_2024", points: 33 }, { name: "pvo_2024", points: 77 }, { name: "portage_open_2024", points: 64 }, { name: "fall_classic_2024", points: 261 }] },
    { id: 14,  place: "15",  name: "Jeff Knight",  top_3_points: 274,  total_points: 300,  events: [{ name: "spring_fling_2024", points: 52 }, { name: "battle_for_baskets_2024", points: 10 }, { name: "pvo_2024", points: 151 }, { name: "man_i_love_frisbee_open_2024", points: 51 }, { name: "portage_open_2024", points: 36 }] },
    ]
    
    export const fa2: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Maya Woods", top_3_points: 105,  total_points: 105,  events: [{ name: "spring_fling_2024", points: 49 }, { name: "battle_for_baskets_2024", points: 26 }, { name: "crocus_crush_2024", points: 10 }, { name: "provincials_2024", points: 10 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 1,  place: "2",  name: "Sarah Mitchell",  top_3_points: 58,  total_points: 58,  events: [{ name: "spring_fling_2024", points: 28 }, { name: "battle_for_baskets_2024", points: 10 }, { name: "pvo_2024", points: 10 }, { name: "portage_open_2024", points: 10 }] },
    ]
    
    export const ma3: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Sam Ritchot",  top_3_points: 628, total_points: 1046,  events: [{ name: "spring_fling_2024", points: 184 }, { name: "battle_for_baskets_2024", points: 221 }, { name: "pvo_2024", points: 134 }, { name: "man_i_love_frisbee_open_2024", points: 60 }, { name: "provincials_2024", points: 101 }, { name: "portage_open_2024", points: 183 }, { name: "fall_classic_2024", points: 163 }] },
    { id: 1,  place: "2",  name: "Bradley Zakaluk",  top_3_points: 564,  total_points: 1011,  events: [{ name: "spring_fling_2024", points: 112 }, { name: "battle_for_baskets_2024", points: 212 }, { name: "pvo_2024", points: 161 }, { name: "man_i_love_frisbee_open_2024", points: 132 }, { name: "provincials_2024", points: 142 }, { name: "portage_open_2024", points: 151 }, { name: "fall_classic_2024", points: 101 }] },
    { id: 2,  place: "3",  name: "Paul Jaarsma",  top_3_points: 557,  total_points: 908,  events: [{ name: "spring_fling_2024", points: 163 }, { name: "battle_for_baskets_2024", points: 192 }, { name: "pvo_2024", points: 92 }, { name: "man_i_love_frisbee_open_2024", points: 91 }, { name: "provincials_2024", points: 162 }, { name: "portage_open_2024", points: 131 }, { name: "fall_classic_2024", points: 77 }] },
    { id: 3,  place: "4",  name: "Rory McIntyre",  top_3_points: 536,  total_points: 536,  events: [{ name: "battle_for_baskets_2024", points: 233 }, { name: "provincials_2024", points: 182 }, { name: "fall_classic_2024", points: 121 }] },
    { id: 4,  place: "5",  name: "Dawson Wiens",  top_3_points: 535,  total_points: 770,  events: [{ name: "battle_for_baskets_2024", points: 181 }, { name: "pvo_2024", points: 113 }, { name: "provincials_2024", points: 173 }, { name: "portage_open_2024", points: 161 }, { name: "fall_classic_2024", points: 142 }] },
    { id: 5,  place: "6",  name: "Corey Harrison",  top_3_points: 505,  total_points: 596,  events: [{ name: "battle_for_baskets_2024", points: 10 }, { name: "man_i_love_frisbee_open_2024", points: 101 }, { name: "provincials_2024", points: 162 }, { name: "portage_open_2024", points: 172 }, { name: "fall_classic_2024", points: 151 }] },
    { id: 6,  place: "7",  name: "Brandon Boyce",  top_3_points: 410, total_points: 468,  events: [{ name: "spring_fling_2024", points: 163 }, { name: "battle_for_baskets_2024", points: 116 }, { name: "man_i_love_frisbee_open_2024", points: 68 }, { name: "provincials_2024", points: 121 }] },
    { id: 7,  place: "8",  name: "Kendall Pries",  top_3_points: 405,  total_points: 636,  events: [{ name: "spring_fling_2024", points: 122 }, { name: "battle_for_baskets_2024", points: 116 }, { name: "pvo_2024", points: 33 }, { name: "man_i_love_frisbee_open_2024", points: 132 }, { name: "portage_open_2024", points: 112 }, { name: "fall_classic_2024", points: 121 }] },
    { id: 8,  place: "9",  name: "Nicholas Sorin",  top_3_points: 378,  total_points: 424,  events: [{ name: "spring_fling_2024", points: 66 }, { name: "battle_for_baskets_2024", points: 181 }, { name: "man_i_love_frisbee_open_2024", points: 34 }, { name: "provincials_2024", points: 111 }, { name: "fall_classic_2024", points: 32 }] },
    { id: 9,  place: "10",  name: "Gordon Merkel",  top_3_points: 369,  total_points: 616,  events: [{ name: "spring_fling_2024", points: 95 }, { name: "battle_for_baskets_2024", points: 133 }, { name: "pvo_2024", points: 71 }, { name: "man_i_love_frisbee_open_2024", points: 34 }, { name: "provincials_2024", points: 101 }, { name: "portage_open_2024", points: 91 }, { name: "fall_classic_2024", points: 91 }] },
    { id: 10,  place: "11",  name: "Kaden Choptuik",  top_3_points: 288,  total_points: 288,  events: [{ name: "battle_for_baskets_2024", points: 116 }, { name: "provincials_2024", points: 71 }, { name: "portage_open_2024", points: 101 }] },
    { id: 11,  place: "12",  name: "Chad Baxter", top_3_points: 223,  total_points: 247,  events: [{ name: "spring_fling_2024", points: 80 }, { name: "battle_for_baskets_2024", points: 48 }, { name: "man_i_love_frisbee_open_2024", points: 10 }, { name: "provincials_2024", points: 34 }, { name: "portage_open_2024", points: 75 }] },
    { id: 12,  place: "13",  name: "Brodie Goertzen",  top_3_points: 163,  total_points: 163,  events: [{ name: "spring_fling_2024", points: 163 }] },
    { id: 13,  place: "14",  name: "Jeff Burns",  top_3_points: 160,  total_points: 160,  events: [{ name: "spring_fling_2024", points: 10 }, { name: "battle_for_baskets_2024", points: 48 }, { name: "pvo_2024", points: 82 }, { name: "portage_open_2024", points: 10 }, { name: "provincials_2024", points: 10 }] },     
    { id: 14,  place: "15",  name: "Dylan Ferris",  top_3_points: 133,  total_points: 133,  events: [{ name: "spring_fling_2024", points: 133 }] },
    { id: 15,  place: "16",  name: "Andrew Ridd",   top_3_points: 100,  total_points: 100,  events: [{ name: "spring_fling_2024", points: 34 }, { name: "battle_for_baskets_2024", points: 66 }] },
    { id: 16,  place: "17",  name: "Nathan Fischer",  top_3_points: 71,  total_points: 71,  events: [{ name: "pvo_2024", points: 22 }, { name: "provincials_2024", points: 49 }] },
    { id: 17,  place: "18",  name: "Joshua Lazarenko",  top_3_points: 66,  total_points: 66,  events: [{ name: "spring_fling_2024", points: 66 }] },
    { id: 18,  place: "19",  name: "Josh Thordarson",  top_3_points: 65,  total_points: 65,  events: [{ name: "provincials_2024", points: 65 }] },
    { id: 19,  place: "20",  name: "Ryan Woods",  top_3_points: 40,  total_points: 40,  events: [{ name: "spring_fling_2024", points: 10 }, { name: "battle_for_baskets_2024", points: 10 }, { name: "provincials_2024", points: 10 }, { name: "fall_classic_2024", points: 10 }] },
    ]
    
    export const fa3: PlayerWithEvents [] = [
    ]
    
    export const ma40: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Dan Edinborough",  top_3_points: 399,  total_points: 517,  events: [{ name: "spring_fling_2024", points: 61 }, { name: "battle_for_baskets_2024", points: 39 }, { name: "pvo_2024", points: 131 }, { name: "provincials_2024", points: 93 }, { name: "portage_open_2024", points: 48 }, { name: "fall_classic_2024", points: 145 }] },
    { id: 1,  place: "2",  name: "John Frommelt",  top_3_points: 256,  total_points: 256,  events: [{ name: "battle_for_baskets_2024", points: 64 }, { name: "pvo_2024", points: 71 }, { name: "portage_open_2024", points: 10 }, { name: "fall_classic_2024", points: 111 }] }, 
    { id: 2,  place: "3",  name: "Derek Bishop",  top_3_points: 193,  total_points: 193,  events: [{ name: "spring_fling_2024", points: 45 }, { name: "battle_for_baskets_2024", points: 26 }, { name: "pvo_2024", points: 102 }, { name: "man_i_love_frisbee_open_2024", points: 10 }, { name: "portage_open_2024", points: 10 } ] },
    { id: 3,  place: "4",  name: "Steve Link",   top_3_points: 122,  total_points: 122,  events: [{ name: "battle_for_baskets_2024", points: 10 }, { name: "pvo_2024", points: 102 }, { name: "portage_open_2024", points: 10 }] },
    ]
    
    export const fa40: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Roberta Ronald", top_3_points: 163,  total_points: 163,  events: [{ name: "spring_fling_2024", points: 10 }, { name: "crocus_crush_2024", points: 10 }, { name: "battle_for_baskets_2024", points: 30 }, { name: "pvo_2024", points: 24 }, { name: "man_i_love_frisbee_open_2024", points: 59 },{ name: "portage_open_2024", points: 10 }, { name: "provincials_2024", points: 10 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 1,  place: "2",  name: "Susan Evans",  top_3_points: 152,  total_points: 152,  events: [{ name: "spring_fling_2024", points: 10 }, { name: "crocus_crush_2024", points: 10 }, { name: "pvo_2024", points: 40 }, { name: "man_i_love_frisbee_open_2024", points: 66 }, { name: "provincials_2024", points: 26 }] },
    ]
    
    export const ma50: PlayerWithEvents [] = [
    { id: 0,  place: "1",  name: "Clark Greenfield",  top_3_points: 215,  total_points: 284,  events: [{ name: "spring_fling_2024", points: 46 }, { name: "battle_for_baskets_2024", points: 60 }, { name: "pvo_2024", points: 61 }, { name: "man_i_love_frisbee_open_2024", points: 64 }, { name: "provincials_2024", points: 43 }, { name: "fall_classic_2024", points: 10 }] },
    { id: 1,  place: "2",  name: "Paul Reder",  top_3_points: 138,  total_points: 138,  events: [{ name: "spring_fling_2024", points: 40 }, { name: "battle_for_baskets_2024", points: 40 }, { name: "pvo_2024", points: 10 }, { name: "man_i_love_frisbee_open_2024", points: 48 }] },
    { id: 2,  place: "3",  name: "Tim Barkley",  top_3_points: 46,  total_points: 46,  events: [{ name: "portage_open_2024", points: 46 }] },
    { id: 3,  place: "4",  name: "Adam McKinty", top_3_points: 0, total_points: 0, events: [] },
    ]
    
    export const fa50: PlayerWithEvents [] = [
    ]
    
    
    