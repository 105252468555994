import { Box, Button, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import DetailsCard from "./DetailsCard";
import LeaderboardDesktopMenu from "./LeaderboardDesktopMenu";
import LeaderboardMobileMenu from "./LeaderboardMobileMenu";
import LeaderboardTabPanel from "./LeaderboardTabPanel";
import { fa1, fa2, fa3, fa40, fa50, fpo, ma1, ma2, ma3, ma40, ma50, mpo } from "./scores";
import { divisions as _divisions } from "./utils";

const divisions: PlayerWithEvents[][] = [mpo, fpo, ma40, fa40, ma50, fa50, ma1, fa1, ma2, fa2, ma3, fa3]

interface LeaderTabProps {
    rows: PlayerWithEvents[];
    division: string;
    leaderboardColumns: GridColDef[];
}

interface TabProps {
    rows: PlayerWithEvents[];
    selected: number;
    index: number;
    leaderboardColumns: GridColDef[];
}

const LeaderTabPanel = (props: LeaderTabProps) => {
    const { rows, division, leaderboardColumns } = props;

    return (
        <Box mb="1rem" mr="1rem">
            <Typography>{division}</Typography>
            {rows.length === 0 ? (
                <Typography align="center">No available data</Typography>
            ) : (
                <DataGrid autoHeight rows={rows} columns={leaderboardColumns} hideFooter disableColumnMenu disableColumnFilter/>
            )}
        </Box>
    )
};

const TabPanel = (props: TabProps) => {
    const { rows, selected, index, leaderboardColumns } = props;

    return (
        <LeaderboardTabPanel value={selected} index={index}>
            {rows.length === 0 ? (
                <Typography align="center">No available data</Typography>
            ) : (
                <DataGrid autoHeight rows={rows} columns={leaderboardColumns} hideFooter disableColumnMenu disableColumnFilter/>
            )}
        </LeaderboardTabPanel>
    );
};

const Leaderboards = () => {
    const [selected, setSelected] = useState<number>(0);
    const isMenuVertical = ({ query: '(max-width: 1100px'});
    const [open, setOpen] = useState<boolean>(false);
    const [eventsInfo, setEventsInfo] = useState<PlayerWithEvents>();

    const handleDetailsClicked = (playerWithEvents: PlayerWithEvents) => {
        setEventsInfo(playerWithEvents);
        setOpen(true);
    };

    const leaderboardColumns: GridColDef[] = [
        {field: 'place', headerName: '#', flex: 1, hideSortIcons: true},
        {field: 'name', headerName: 'Name', flex: 1, hideSortIcons: true},
        {field: 'top_3_points', headerName: 'Top 3', flex: 1, hideSortIcons: true},
        {field: 'details', headerName: '', flex: 1, hideSortIcons: true, renderCell: (props) => {
            return (
                <Button variant="contained" onClick={() => handleDetailsClicked(props.row)}>Details</Button>
            );
        }},
    ];
    
    const handleChange = (e: React.SyntheticEvent, value: number) => {
        setSelected(value);
    };

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <>
        <DetailsCard data={eventsInfo} isOpen={open} onClose={handleClose}/>
        <Stack direction="column" alignItems="center" sx={{ overflowY: 'auto'}}>
            <Typography variant="h4"><strong>Leaderboards</strong></Typography>
            {isMenuVertical && (
                <LeaderboardMobileMenu selected={selected} handleChange={handleChange} />
            )}
            {!isMenuVertical && (
                <LeaderboardDesktopMenu selected={selected} handleChange={handleChange}/>  
            )}
            <LeaderboardTabPanel value={selected} index={0}>
                {divisions.map((division, i) => (
                    <LeaderTabPanel rows={division.slice(0,3)} division={_divisions[i+1]} key={i} leaderboardColumns={leaderboardColumns}/>
                ))}
            </LeaderboardTabPanel>
            {divisions.map((division, i) => (
                <TabPanel rows={division} selected={selected} index={i+1} key={i+1} leaderboardColumns={leaderboardColumns}/>
            ))}
        </Stack>
        </>
    )
}

export default Leaderboards;
