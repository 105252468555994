import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import accurate_leasing from "../../assets/AccurateLeasing.png";
import dr_logo_bar from "../../assets/DR_bar.png";
import dr_logo_vert from "../../assets/DR_vert.png";
import kilter_brewing_co from "../../assets/KilterBrewingCo.webp";
import odins_eye from "../../assets/OdinsEye.png";
import avail from "../../assets/avail.png";
import chaga from "../../assets/chaga_logo.png";
import dug_and_bettys from "../../assets/dug_and_bettys.png";
import ernst_hansch from "../../assets/ernst_hansch_logo.png";
import jeffreys from "../../assets/jeffreys_logo.png";
import local from "../../assets/local_logo.png";
import local_public_eatery from "../../assets/localpubliceatery.png";
import meldas from "../../assets/meldas_logo.png";
import victory_pints from "../../assets/victorypints.webp";

export function Sponsors () {
    const [expanded, setExpanded] = useState<boolean>(false);

    return (
        <Box display="flex" flex={1} alignItems="top" justifyContent="center" sx={{ overflowY: 'auto', overflowX: 'hidden'}}>
            <Stack direction="column" alignItems="center" mr="1rem" mt="1rem">
                <Typography variant="h5" mt="1rem"><strong>2024</strong></Typography>
                <Box component="img" src={accurate_leasing} alt="accurate leasing" height="200px" display={{ xs: 'none', sm: 'block' }} mb="1rem"/>
                <Box component="img" src={accurate_leasing} alt="accurate leasing" height="100px" display={{ xs: 'block', sm: 'none' }} mb="1rem"/>
                <Box component="img" src={chaga} alt="chaga tree co." height="250px" display="block" mb="1rem"/>
                <Box component="img" src={dug_and_bettys} alt="dug and bettys" height="250px" display="block" mb="1rem"/>
                <Box component="img" src={jeffreys} alt="jeffreys restaurant" height="250px" display="block" mb="1rem"/>
                <Box component="img" src={local} alt="local public eatery" height="250px" display="block" mb="1rem"/>
                <Box component="img" src={meldas} alt="meldas diner" height="250px" display="block" mb="1rem"/>
                <Box component="img" src={ernst_hansch} alt="ernst_hansch" height="250px" display="block" mb="1rem"/>
                <Stack direction="row" alignItems="center">
                    <Typography variant="h5" mt="1rem">2023</Typography>
                    <IconButton sx={{ mr: '1rem'}} onClick={() => setExpanded(!expanded)}>
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Stack>
                <Collapse in={expanded}>
                    <Stack direction="column" alignItems="center" mr="1rem" mt="1rem">
                        <Box component="img" src={dr_logo_bar} alt="disc republic" height={{ sm: "105px", md:"170px"}} display={{ xs: 'none', sm: 'block' }}/>
                        <Box component="img" src={dr_logo_vert} alt="disc republic" height="100px" mb="3rem" display={{ xs: 'block', sm: 'none' }}/>
                        <Box component="img" bgcolor="black" src={kilter_brewing_co} alt="kilter brewing co" height="200px" display={{ xs: 'none', sm: 'block' }} mb="1rem"/>
                        <Box component="img" bgcolor="black" src={kilter_brewing_co} alt="kilter brewing co" height="100px" display={{ xs: 'block', sm: 'none' }} mb="1rem"/>
                        <Box component="img" src={victory_pints} alt="victory pints" height="200px" display={{ xs: 'none', sm: 'block' }} mb="1rem"/>
                        <Box component="img" src={victory_pints} alt="victory pints" height="100px" display={{ xs: 'block', sm: 'none' }} mb="1rem"/>
                        <Box component="img" src={accurate_leasing} alt="accurate leasing" height="200px" display={{ xs: 'none', sm: 'block' }} mb="1rem"/>
                        <Box component="img" src={accurate_leasing} alt="accurate leasing" height="100px" display={{ xs: 'block', sm: 'none' }} mb="1rem"/>
                        <Box component="img" src={local_public_eatery} alt="local public eatery" height="200px" display={{ xs: 'none', sm: 'block' }} mb="1rem"/>
                        <Box component="img" src={local_public_eatery} alt="local public eatery" height="100px" display={{ xs: 'block', sm: 'none' }} mb="1rem"/>
                        <Box component="img" src={avail} alt="avail" height="300px" display={{ xs: 'none', sm: 'block' }} mb="1rem"/>
                        <Box component="img" src={avail} alt="avail" height="200px" display={{ xs: 'block', sm: 'none' }} mb="1rem"/>
                        <Box component="img" src={odins_eye} alt="odins eye" height="300px" display={{ xs: 'none', sm: 'block' }} mb="1rem"/>
                        <Box component="img" src={odins_eye} alt="odins eye" height="200px" display={{ xs: 'block', sm: 'none' }} mb="1rem"/>
                    </Stack>
                </Collapse>
            </Stack>
        </Box>
    );
}