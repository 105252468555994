import { Box, Modal, Typography } from "@mui/material";
import { eventNameToReadableName } from "./utils";

const detailsModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

const typographyStyle = {
    textTransform: 'capitalize',
};

interface Props {
    data?: PlayerWithEvents;
    isOpen: boolean;
    onClose: VoidFunction;
}

export default function DetailsCard(props: Props) {
    const { data, isOpen, onClose } = props;

    return (
        <Modal open={isOpen} onClose={onClose}>
            {data !== undefined ? (<Box sx={{...detailsModalStyle}}>
                <Typography variant="h6">
                    Event info for {data.name}
                </Typography>
                <Typography sx={{...typographyStyle}}>top 3 points: {data.top_3_points}</Typography>
                <Typography sx={{...typographyStyle}}>total points: {data.total_points}</Typography>
                {data.events.map((event) => (
                    <Typography sx={{...typographyStyle}} key={event.name}>
                        {eventNameToReadableName(event.name)}: {event.points}
                    </Typography>
                ))}
                <hr/>
                <Typography>Breakdown: top 3 points = 3 highest event point totals + 10 points per event played</Typography>
            </Box>) : (<></>)}
        </Modal>
    );
}