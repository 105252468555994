export const divisions: string[] = [
    'LEADERS',
    'MPO',
    'FPO',
    'MA40',
    'FA40',
    'MA50',
    'FA50',
    'MA1',
    'FA1',
    'MA2',
    'FA2',
    'MA3',
    'FA3'
]

export function eventNameToReadableName(name: string) : string {
    const tokens = name.trim().split('_');
    tokens.pop()
    return tokens.join(' ');
}